.globalList {
    padding: 50px;

    .globalStatisticContainer {
        padding-top: 10px;
        max-width: 1180px;
        margin: 0 auto 25px;

        .text_center {
            text-align: center;
            color: $global_p_color;
        }

        input[type='checkbox'] {
            accent-color: rgb(153 200 255);
          caret-color: #00bb00;
        }

        p {
            color: $global_p_color;

            a {
                color: #3391ff;
            }
        }

        span {
            color: rgb(232, 230, 227);
        }

        .competition_type_selector {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            label {
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 2;
                letter-spacing: 0.00938em;
                color: $global_p_color;
            }
        }

        .competitionsMySubmissionFilter {
            color: #666666;
            margin: 0 auto;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            .checkbox {
                margin-right: 5px;
            }
        }
    }
}
.slider-handles {
    div:first-child {
        z-index: 6;
        width: 8px;
    }
    div:nth-child(3) {
        width: 8px;
        padding-right: 5px;
    }
}
.button-wrapper {
    text-align: center;
    margin-top: 25px;
    padding: 25px 0;
    .leaderbord_link {
        background: none;
        border: 1px solid $dark_font_color;
        color: $dark_font_color;
        padding: 20px;
        margin-top: 25px;
        text-decoration: none;
    }
}
.topTenHeader {
    text-align: center;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 36px;
    color: $global_header_color;
}
.global_list {
    font-family: $average_font;
    color: $global_p_color;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    overflow-x: auto;
    tr,
    td {
        border-collapse: collapse;
    }
    tr {
        &.subTable {
            &:nth-child(even) {
                background-color: #d9e1e6;
            }
            &:nth-child(4n + 0) {
                background-color: #f2f5f6;
            }
            &.userStrategy {
                background-color: $contrast_color;
                .field_item {
                    color: $light_main_color;
                    span {
                        color: $light_main_color;
                    }
                }
            }
            svg {
                cursor: pointer;
                path {
                    color: $light_border;
                }
            }
        }
    }
    .shrink-toggle {
        span {
            color: $light_border;
            path {
                color: $light_border;
            }
        }
    }
    td {
        padding: 0.75rem;
        vertical-align: middle;
        word-break: break-all;
    }

    .profit_preview {
        background-image: url('../img/sparkline.png');
        cursor: pointer;
        width: 140px;
        height: 50px;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    }

    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      display: inline-block;
      margin: 0 auto;
    }

    .dot.red {
      background-color: darkred;
    }

    .dot.green {
      background-color: darkgreen;
    }

    .subTable {
        font-size: 16px;
        .name {
            text-align: left;
            max-width: 300px;
            min-width: 150px;
        }
        .author {
            max-width: 300px;
            min-width: 80px;
        }
        .rank,
        .score {
            min-width: 80px;
        }
        .prize {
            white-space: nowrap;
        }
        .non_wrap {
            white-space: nowrap;
        }
    }
    .topTenHeader {
        margin-top: 5%;
        text-align: center;
        font-family: $average_font;
        color: $middle_font_color;
        font-size: 1.6em;
        text-transform: uppercase;
        font-weight: bold;
    }
    .template_container {
        margin: 0;
        border: none;
        background: $light_main_color;
        color: $dark_font_color;
    }
    .subTableHeader {
        a {
            color: rgb(105, 151, 244);
        }

        thead {
            tr {
                background-color: rgb(27, 48, 68);
            }

            th {
                background: rgb(27, 48, 68);
                color: rgb(232, 230, 227);
                border: none;
            }
        }

        tbody {
            tr {
                &:nth-child(2n + 1) {
                    background-color: rgb(26, 35, 38);
                }
                &:nth-child(4n + 1) {
                    background-color: rgb(42, 45, 47);
                }
            }
        }
        border: none;
        width: 100%;
        border-collapse: collapse;
        overflow: hidden;
        tr,
        th {
            border-collapse: collapse;
        }
        tr {
            text-align: center;
            vertical-align: bottom;

            .field_item_os {
                white-space: nowrap;
                font-weight: bold;
                color: red;
            }

            .field_item_os_positive {
                white-space: nowrap;
                color: green;
                font-weight: bold;
            }

            &.shrink-wrapper {
                background-color: rgb(38, 41, 42);
                tr {
                    &:nth-child(even) {
                        background-color: rgb(38, 41, 42);
                    }

                    &:nth-child(odd) {
                        background-color: rgb(38, 41, 42);
                    }
                }

                td {
                    text-align: left;
                    padding: 0 10px 0 3px;
                }

                div {
                    padding: 0;
                    margin: 0;
                    max-height: 0;
                    overflow: hidden;
                    transition: max-height 0.5s ease-out, padding 0.3s linear 0.4s;
                }

                &.m-fadeOut {
                    div {
                        padding: 0;
                        max-height: 0;
                    }
                }

                &.m-fadeIn {
                    div {
                        padding: 5px;
                        overflow: hidden;
                        max-height: 500px;
                        transition: max-height 0.5s ease-in;
                    }
                }
            }
            th {
                cursor: pointer;
                padding: 10px 5px;
                text-align: center;
                vertical-align: middle;
                &.author,
                &.rank {
                    cursor: pointer;
                }
                span {
                    text-align: center;
                }

                &.name {
                    font-family: $average_font;
                    text-align: left;
                    padding: 10px;
                    cursor: default;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .globalStatistisText {
        margin-bottom: 20px;
    }
    .global_list {
        .subTable {
            font-size: 14px;
        }
    }
    .subTableHeader {
        tr {
            .submission_id,
            .hide_for_mobile,
            .author,
            .is {
                display: none;
            }
            .shrink-author,
            .shrink-is {
                display: table-row;
            }
        }
    }
}
@media screen and (min-width: 1200px) {
    .global_list .subTableHeader tr.shrink-wrapper {
        display: none;
    }
    .statistics_charts {
        width: 100%;
        max-width: 800px;
    }
    .statistics_subinfo {
        width: 100%;
        max-width: 400px;
    }
    .subTableHeader {
        tr {
            .shrink-toggle {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .subTableHeader {
        tr {
            .allocations {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .subTableHeader {
        tr {
            .author,
            .instruments,
            .sub_duration,
            .tr_duration,
            .max_dd,
            .holdingTime {
                display: none;
            }
            .shrink-author,
            .shrink-is {
                display: none;
            }
        }
    }
    .statisticsWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 480px) {
    #global-list {
        padding: 0;
    }
    .globalList {
        padding: 20px;

        .profit_preview {
            width: 80px;
            height: 40px;
        }
        td {
            padding: 0.35rem;
        }

        .subTable {
            font-size: 12px;

            .name {
                min-width: 60px;
            }
        }
    }
}
