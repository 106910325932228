.termsWrapper {
    padding: 50px;
    margin-top: 58px;
    padding-bottom: 5%;
    font-family: $average_font;
    color: $global_p_color;

    ol,
    ul {
        li {
            list-style-type: none;
        }
    }
    a {
        color: $global_link_color;
    }

    .personal-data {
        max-width: 1180px;
        margin: 0 auto;

        h1 {
            text-align: left;
            font-size: 1.5em;
            margin-top: 0;
            margin-bottom: 10px;
            font-family: $header_font;
            font-weight: normal;
            color: $global_header_color;
        }

        .section_header {
            text-align: left;
            font-size: 16px;
            font-family: $header_font;
            margin: 20px 0;
            color: $global_strong_color;
        }

        .section_table {
            border-collapse: collapse;

            tr {
                border: 1px solid $light_font_color;

                th,
                td {
                    text-align: left;
                    padding: 10px;
                    border: 1px solid $light_font_color;
                }

                th {
                    font-family: $header_font;
                }

                td {
                    vertical-align: top;
                }
            }
        }

        .section_list {
            display: none;

            li {
                padding: 10px;
            }

            .li_header {
                font-family: $header_font;
            }

            .li_text {
                padding-bottom: 5px;
            }
        }

        .section_text {
            font-family: $average_font;
            text-align: left;
            font-size: 1em;
            line-height: 1.6;
        }

        .lastVersion {
            font-family: $average_font;
        }
    }
}

.quantiacs_personal_data {
    max-width: 1180px;
    margin: 0 auto;
    font-family: 'Gotham Pro Regular';
    text-align: justify;
    line-height: 1.6;

    h1 {
        text-align: center;
        margin-top: 0;
        text-transform: none;
        font-size: 2.5rem;
    }

    p {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    strong {
        color: $global_strong_color;
        font-weight: bold;
    }
}

@media screen and (max-width: 768px) {
    .section_list {
        display: block !important;
    }
    .section_table {
        display: none;
    }
}

@media (max-width: 480px) {
    .termsWrapper {
        padding: 0;
    }
    section {
        padding: 20px !important;

        h1 {
            font-size: 20px !important;
        }
    }
}
