.quantiacs-logo {
    background: url('../img/quantiacs-logo.png') no-repeat;
    top: 2px;
}

header {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: $dark_main_color;
    z-index: 10;
}
.top_header {
    padding: 10px 0;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $dark_main_color;
    max-width: 1180px;
    margin: 0 auto;
    box-sizing: border-box;
    .logoWrapper {
        padding-left: 5px;
        width: 25%;
        .logo {
            color: $light_main_color;
            display: inline-block;
            font-size: 2em;
            text-align: left;
            position: relative;
            background-size: contain;
            width: 149px;
            text-indent: -9999px;
        }
    }

    .dropdownmenu {
        width: 55%;
        display: flex;
        vertical-align: middle;
        justify-content: space-between;
        ul,
        li {
            margin: 0;
            padding: 0;
        }
        ul {
            list-style: none;
            width: 100%;
            display: flex;
            justify-content: space-around;
        }
        li {
            float: left;
            position: relative;
            width: auto;
            .dropdown-item {
                color: $middle_main_color;
            }
        }
        a {
            background: inherit;
            color: $light_main_color;
            display: block;
            font-family: $header_font;
            padding: 0;
            padding-left: 2.5px;
            padding-right: 2.5px;
            text-align: center;
            text-decoration: none;
            font-size: 14px;
            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
            white-space: nowrap;
        }

        .quantiacs_community_link {
            color: #1bbafb;
        }
        li:hover {
            .nav-link {
                color: $light_font_color;
            }
        }

        .submenu {
            transition-delay: 200ms;
            left: 0;
            opacity: 0;
            position: absolute;
            top: 20px;
            visibility: hidden;
            z-index: 5;
            min-width: 160px;
        }
        li:hover ul.submenu {
            opacity: 0.9;
            color: $light_main_color;
            top: 40px; /* adjust this as per top nav padding top & bottom comes */
            visibility: visible;
        }
        .submenu li {
            float: none;
            width: 100%;
        }
        .submenu a {
            color: $light_main_color;
            background-color: $dark_main_color;
            text-align: left;
            font-size: 0.8em;
            &:hover {
                color: $contrast_color;
            }
        }
    }

    .headerWrapper {
        text-align: right;
        display: block;
        position: absolute;
        left: 40px;
        padding: 0;

        .mobileToggle {
            margin-top: 10px;
            font-family: $average_font;
            color: $light_main_color;
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 5px;
            padding: 10px;
            padding-right: 45px;
            &:hover {
                color: $contrast_color;
            }
        }

        #toggle {
            display: none;

            &:not(:checked) + nav {
                width: 0;
                overflow: hidden;
            }

            &:checked + nav {
                width: 300px;
                overflow: visible;
            }

            &:checked + nav li {
                opacity: 1;
            }
        }

        nav {
            position: fixed;
            top: 50px;
            background: $dark_main_color;
            left: 0;
            z-index: 10;
            transition: 0.5s;
            height: calc(100vh - 50px);
            width: 300px;

            ul {
                padding: 0;
            }

            li {
                opacity: 0;
                transition: opacity 0.4s ease;
                list-style-type: none;
            }

            .nav-link {
                background: inherit;
                color: #ffffff;
                display: block;
                font-family: $header_font;
                text-align: left;
                text-transform: uppercase;
                text-decoration: none;
                font-size: 14px;
                -webkit-transition: all 0.25s ease;
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #383838;
                padding: 0 25px 2px 30px;

                &:hover {
                    color: $light_font_color;
                }
            }

            .quantiacs_community_link {
                color: #1bbafb;
            }

            .submenu {
                width: fit-content;

                li {
                    list-style-type: none;
                    justify-content: center;

                    a {
                        background: inherit;
                        color: $light_font_color;
                        display: block;
                        font-family: 'Roboto', sans-serif;
                        padding: 10px 5px;
                        text-align: left;
                        text-transform: uppercase;
                        text-decoration: none;
                        font-size: 0.7em;
                        font-weight: bold;
                        -webkit-transition: all 0.25s ease;
                        transition: all 0.25s ease;
                    }
                }
            }

            .soc_buttons {
                justify-content: center;
            }
        }

        /* menu icon */

        .menu-icon {
            cursor: pointer;
            float: right;
            padding: 0;
            user-select: none;

            .navicon {
                background: $light_main_color;
                display: block;
                height: 2px;
                position: relative;
                transition: background 0.2s ease-out;
                width: 18px;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    background: $light_main_color;
                    transition: all 0.2s ease-out;
                }

                &:before {
                    top: 5px;
                }

                &:after {
                    top: -5px;
                }
            }
        }
        #nav-icon1,
        #nav-icon2,
        #nav-icon3,
        #nav-icon4 {
            width: 30px;
            height: 20px;
            margin-top: 5px;
            position: relative;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
        }

        #nav-icon1 span,
        #nav-icon3 span,
        #nav-icon4 span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: $light_main_color;
            border-radius: 9px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
        }

        /* Icon 3 */

        #nav-icon3 span:nth-child(1) {
            top: 0px;
        }
        #nav-icon3:hover {
            span {
                background: $light_font_color;
            }
        }
        #nav-icon3 span:nth-child(2),
        #nav-icon3 span:nth-child(3) {
            top: 9px;
        }

        #nav-icon3 span:nth-child(4) {
            top: 18px;
        }

        #nav-icon3.open span:nth-child(1) {
            top: 9px;
            width: 0%;
            left: 50%;
        }

        #nav-icon3.open span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        #nav-icon3.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        #nav-icon3.open span:nth-child(4) {
            top: 9px;
            width: 0%;
            left: 50%;
        }
    }

    .langWrapper {
        max-width: 150px;
        .myAccountLink {
            color: $light_main_color;
            cursor: pointer;
            text-decoration: none;
            font-family: $header_font;
            font-size: 14px;
            transition: all 300ms ease-in;
            margin-left: 20px;
            white-space: nowrap;
            &:hover {
                color: $light_font_color;
            }
        }

        .languagePickerWrapper {
            position: relative;
            width: 40px;
            height: 30px;
            color: $light_main_color;
            padding-left: 5px;
            &::before {
                position: absolute;
                left: -5px;
                top: 50%;
                content: '';
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid $light_main_color;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
            }
            &.ru {
                ul {
                    display: flex;
                    flex-direction: column;
                    li:first-child {
                        order: 1;
                    }
                    li:nth-child(2) {
                        order: 2;
                    }
                    li.ru {
                        visibility: visible !important;
                    }
                }
            }

            &.en {
                ul {
                    display: flex;
                    flex-direction: column;

                    li:first-child {
                        order: 2;
                    }

                    li:nth-child(2) {
                        order: 1;
                    }

                    li.en {
                        visibility: visible !important;
                    }
                }
            }

            &:hover {
                &::before {
                    border-top: 4px solid $light_font_color;
                }
                li {
                    visibility: visible !important;
                }
            }

            ul {
                color: white;
                margin: 0;
                padding: 0;
                align-items: center;

                &:hover {
                    background-color: $dark_main_color;
                }

                li {
                    cursor: pointer;
                    list-style-type: none;
                    font-family: $header_font;
                    color: white;
                    visibility: hidden;
                    font-size: 14px;

                    &:hover {
                        color: $light_font_color;
                    }
                    &.ru {
                        //background: url("../img/languageIcons/iconfinderRussia.png");
                        background-size: contain;
                    }
                    &.en {
                        //background: url("../img/languageIcons/iconfinderUK.png");
                        background-size: contain;
                    }
                }
            }
        }

        .usernameLinkWrapper {
            background: $dark_main_color;
            align-items: center;
            position: relative;
            transition: all ease-in 300ms;
            .usernameHeader {
                display: none;
            }
            &:hover {
                .chevron {
                    color: $light_main_color;
                    cursor: pointer;
                    width: 20px;
                    &::after {
                        position: absolute;
                        right: 0;
                        top: 30%;
                        content: '';
                        width: 0;
                        height: 0;
                        border-left: 4px solid transparent;
                        border-right: 4px solid transparent;
                        border-bottom: 4px solid $light_main_color;
                        border-top: 4px solid transparent;
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 5px;
                        pointer-events: none;
                    }
                }

                ul {
                    z-index: 1000;
                    display: block;
                    position: absolute;
                    background-color: $dark_main_color;
                    color: $light_main_color;
                    width: 150px;
                    top: 30px;
                    right: -20px;
                    padding: 10px 5px 10px 10px;
                    margin: 0;

                    li {
                        list-style-type: none;
                        margin: 5px;
                        cursor: pointer;
                        font-family: $header_font;
                        text-align: center;
                        a,
                        span {
                            color: $light_main_color;
                            font-family: $header_font;
                            text-decoration: none;
                            font-size: 14px;
                        }
                        &:hover {
                            color: $light_font_color;
                            a,
                            span {
                                color: $light_font_color;
                            }
                        }
                    }
                }
            }

            .usernameLink {
                color: $light_main_color;
                cursor: pointer;
                width: fit-content;
                max-width: 150px;
                text-decoration: none;
                font-family: $average_font;
                margin-left: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding: 0 0 0 10px;
            }
            .usernameIcon {
                display: none;
            }

            .chevron {
                color: $light_main_color;
                cursor: pointer;
                width: 20px;
                &::after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    content: '';
                    width: 0;
                    height: 0;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid $light_main_color;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 5px;
                }
            }

            ul {
                display: none;
            }
        }
    }

    .languageSelect {
        font-family: $average_font;
        font-weight: bold;
        font-size: 1em;
        border: none;
        height: fit-content;
        width: 50px;
    }

    .soc_buttons {
        //border-left: 1px solid $dark_font_color;
        display: flex;
        margin-top: 30px;

        a {
            //font-size: 16px;
            //margin-left: 8px;
            padding-top: 0;
            color: $light_main_color;
            text-decoration: none;
            margin-right: 20px;

            &:hover {
                .fa {
                    color: $contrast_color;
                }
            }
        }

        .soc_buttons_vk,
        .soc_buttons_fb,
        .soc_buttons_in,
        .soc_buttons_tg,
        .soc_buttons_zen {
            height: 20px;
            width: 20px;
            padding: 0;
            background: url('../img/soc_icons.png') no-repeat;
            display: inline-block;
            &:hover {
                opacity: 0.7;
            }
        }

        .soc_buttons_vk {
            background-position: -41px -9px;
        }

        .soc_buttons_fb {
            background-position: -74px -11px;
        }

        .soc_buttons_in {
            background-position: -75px -44px;
        }

        .soc_buttons_tg {
            background-position: -41px -43px;
        }

        .soc_buttons_zen {
            background-position: -9px -42px;
        }
    }

    .header_topline {
        background-color: $light_main_color;
        padding: 0;
        color: $dark_main_color;
        position: relative;
    }
}

footer {
    background: $dark_main_color;
    font-family: $average_font;
    clear: both;
    position: relative;
    height: 25em;
    padding: 50px;
    .wrapper {
        max-width: 1180px;
        margin: 0 auto;
        .socButtonWrapper {
            width: 100%;
            min-width: 100px;
            display: inline-flex;
            justify-content: space-evenly;
        }
        .copywriteWrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 70px;
            margin-bottom: 1rem;
            .copywrite {
                color: $light_border !important;
                font-size: 16px;
                font-family: $average_font !important;
            }
        }
    }

    .main_footerWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
    }

    .flex_rows_break {
        flex-basis: 100%;
        height: 0;

        & + .main_footer {
            width: 100%;
        }
    }

    .main_footer {
        //margin: 0 15% 0 5%;

        .logo {
            color: $light_main_color;
            display: inline-block;
            font-size: 2em;
            //padding-top: 10px;
            text-align: left;
            position: relative;
            background-size: contain;
            width: 10%;
            min-width: 145px;
            height: 30px;
            text-indent: -9999px;
            margin: 0 5% 3% 5%;
        }

        .footerMenu {
            ul,
            li {
                padding: 0;
                white-space: nowrap;
                text-align: center;
                margin: 0 0 15px;

                a {
                    text-align: center;
                    font-size: 16px;
                    font-family: $average_font;
                }
            }
            ul {
                list-style: none;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                background: $dark_main_color;
            }
            li {
                margin: 0 7% 0 0;
                position: relative;
                width: 100%;
                .dropdown-item {
                    color: $middle_main_color;
                }
                &.copyrightWrap {
                    padding: 6% 0;
                    color: $light_font_color;
                    font-size: 0.8em;
                    width: 100%;
                    white-space: nowrap;
                    a {
                        margin-right: 10%;
                        color: $light_border;
                        padding-top: 0;
                        text-decoration: none;
                        &:hover {
                            color: $light_main_color;
                            text-decoration: underline;
                        }
                    }
                }
            }
            a {
                background: inherit;
                margin-bottom: 15px;
                display: block;
                font-family: $average_font;
                text-align: left;
                text-decoration: none;
                font-size: 0.9em;
                -webkit-transition: all 0.25s ease;
                -moz-transition: all 0.25s ease;
                -ms-transition: all 0.25s ease;
                -o-transition: all 0.25s ease;
                transition: all 0.25s ease;
                color: $light_border;
                padding: 0 10px 10px;

                svg,
                path {
                    color: #35ade1;
                }
            }
            li:hover {
                .nav-link {
                    color: $light_main_color;
                    text-decoration: underline;
                }
            }

            .submenu {
                transition-delay: 200ms;
                left: 0;
                opacity: 0;
                position: absolute;
                top: 20px;
                visibility: hidden;
                z-index: 5;
                min-width: 160px;
            }
            li:hover ul.submenu {
                opacity: 0.9;
                color: $light_main_color;
                top: 40px; /* adjust this as per top nav padding top & bottom comes */
                visibility: visible;
            }
            .submenu li {
                float: none;
                width: 100%;
            }
            .submenu a {
                color: $light_main_color;
                background-color: $dark_main_color;
                text-align: left;
                font-size: 0.8em;
                &:hover {
                    color: $contrast_color;
                }
            }
        }
    }
}

/* menu */

.menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

/* menu btn */

.menu-btn {
    display: none;

    &:checked ~ .menu {
        max-height: 240px;
    }

    &:checked ~ .menu-icon .navicon {
        background: transparent;
    }

    &:checked ~ .menu-icon .navicon:before {
        transform: rotate(-45deg);
    }

    &:checked ~ .menu-icon .navicon:after {
        transform: rotate(45deg);
    }

    &:checked ~ .menu-icon:not(.steps) .navicon:before,
    &:checked ~ .menu-icon:not(.steps) .navicon:after {
        top: 0;
    }
}

@media (max-width: 650px) {
    .top_header {
        padding: 10px 0px;
        width: 90%;
    }
    .usernameLink {
        display: none;
    }
    .usernameHeader {
        display: block !important;
        span {
            color: $contrast_color;
            word-break: break-word;
        }
    }
    .usernameIcon {
        display: block !important;
        padding-bottom: 3px;
        path {
            color: $light_main_color !important;
        }
    }
}

@media only screen and (min-width: 768px) {
    .top_header {
        .headerWrapper {
            display: none;
        }

        .langWrapper {
            width: 20%;
        }

        .dropdownmenu,
        .myAccountLink {
            display: block;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 900px) {
    footer {
        padding-right: 2px;
        padding-left: 2px;
    }
    .dropdownmenu {
        display: none !important;
    }

    .headerWrapper {
        display: block !important;
    }
    .top_header {
        padding: 10px 30px;
        position: fixed;
        top: 0;
        width: 100%;
        .logoWrapper {
            width: 80%;
            text-align: center;
            padding-left: 20%;
        }
        .languagePickerWrapper {
            width: 25px;
            &::before {
                display: none !important;
            }
        }
        .myAccountLink {
            margin-left: 0 !important;
        }
    }

    footer {
        height: auto;
        background: $dark_main_color;
        width: 100%;
        .copywrite {
            font-size: 12px !important;
        }
        .wrapper {
            .copywriteWrapper {
                padding-top: 30px;
                .main_footer {
                    text-align: center;
                    .logo {
                        margin-bottom: 0;
                    }
                }
                .copywrite {
                    text-align: center;
                }
            }
        }

        .main_footerWrapper {
            flex-direction: column;
        }

        .main_footer {
            margin: 0;
            width: 100%;

            &:last-child {
                margin: 0;
            }
            .footerMenu {
                ul,
                li {
                    margin: 0;
                }
                li {
                    &.copyrightWrap {
                        white-space: normal;
                        box-sizing: border-box;
                    }
                    .socButtonWrapper {
                        justify-content: center;
                        a {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 400px) {
    .logoWrapper {
        width: 60% !important;
        text-align: center;
        .logo {
            min-width: 30px !important;
            max-width: 30px !important;
            background-size: cover !important;
        }
    }
    .languagePickerWrapper {
        width: 40px !important;
    }
}
