// imports
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css?family=Merriweather:300|Montserrat+Alternates:400,600,700,800&display=swap&subset=cyrillic');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700|PT+Sans:700|Rubik:700,900|Source+Sans+Pro:400,700|Ubuntu:700&display=swap');

// font faces
@font-face {
    font-family: 'Gotham Pro Light';
    src: url(GothamPro-Light.eot);
    src: url(GothamPro-Light.ttf);
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Regular';
    src: url(GothamPro.eot);
    src: url(GothamPro.ttf);
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Medium';
    src: url(GothamPro-Medium.eot);
    src: url(GothamPro-Medium.ttf);
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Gotham Pro Bold';
    src: url(GothamPro-Bold.eot);
    src: url(GothamPro-Bold.ttf);
    font-weight: normal;
    font-style: normal;
}

//Fonts
$light_font: 'Gotham Pro Light';
$average_font: 'Gotham Pro Regular';
$header_font: 'Gotham Pro Medium';
$bold_font: 'Gotham Pro Bold';

// Variables
$light_main_color: #ffffff;
$light_border: #cccccc;

$contrast_color: #d66d36;
$additional_color: #1395ba;

$middle_main_color: rgb(23, 37, 42);
$middle_font_color: #666666;

$dark_main_color: rgb(0, 0, 0);
$dark_p_color: rgb(232, 230, 227);
$dark_font_color: #333333;
$accent_dark: #223c55;

$light_font_color: #999999;
$accent_color: #ff6666;
$accent_dark: #223c55;

$global_header_color: rgb(168, 160, 149);
$global_p_color: rgb(168, 160, 149);
$global_label_color: rgba(232, 230, 227, 0.54);
$global_input_color: rgba(232, 230, 227);
$global_select_color: rgb(168, 160, 149);
$global_select_border_color: rgb(77, 83, 86);
$global_link_color: rgb(102, 172, 244);
$global_textarea_color: rgb(24, 26, 27);
$global_box_color: rgb(23, 37, 42);
$global_strong_color: rgb(223, 220, 216);

// Mixins
@mixin animation($time) {
    animation: $time;
}
@mixin transition($t_time) {
    transition: $t_time;
}

// Global Styles

body {
    overflow-x: hidden;
    margin: 0;
    background-color: rgb(24, 26, 27);
    font-family: $average_font;
}

#root,
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}

.mainWrapper{
  min-height: 95vh;
}

h1 {
    font-family: $header_font;
}
h2,
h3 {
    margin-top: 0;
    color: $global_header_color;
    font-family: $header_font;
    font-weight: normal;
}

select {
    width: 150px;
    padding: 5px 35px 5px 5px;
    font-size: 16px;
    border: 1px solid $light_main_color;
    height: 34px;
    background-color: inherit;
    margin-left: 5px;
    color: $light_main_color;
    outline: none;
}

.errorWrapper {
    width: 100%;
    max-width: 500px;
    margin: 3% auto;
    padding: 3% 3% 5%;
    text-align: center;
    h4 {
        font-family: $average_font;
        font-size: 1.5em;
        font-weight: normal;
        color: $middle_font_color;
    }
    a {
        background: $contrast_color;
        color: $light_main_color;
        text-decoration: none;
        padding: 10px 20px;
        text-transform: uppercase;
        font-family: $average_font;
        border-radius: 5px;
    }
}
.career_main_body {
    max-width: 1180px;
    margin: 0 auto;
}

// Import other scss files
@import 'header';
@import 'homepage';
@import 'global_list_mini';
@import 'career';
@import 'students';
@import 'interviews';
@import 'accordion';
@import 'contacts';
@import 'termsofuse';
@import 'openpositions';
@import 'rules';
@import 'rulesQuantiacs';
@import 'vacancyDescription';
@import '404';
@import 'faq';
@import 'learn';
@import 'cookies';
@import 'competitions';
