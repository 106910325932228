.termsWrapper_learn {
    padding: 50px;
    margin-top: 58px;
    .section_header {
        font-family: $header_font;
        font-size: 16px;
        color: $middle_font_color;
    }
    .tabs_body {
        width: 100%;
    }

    .actionButtonWrapper {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        .warningWrapper {
            margin-top: 20px;
            p {
                font-size: 1em !important;
                padding-bottom: 3px;
                margin-bottom: auto !important;
            }
        }
        .actionButton {
            text-align: center;
            color: $light_main_color;
            background: $contrast_color;
            border: 1px solid $contrast_color;
            cursor: pointer;
            padding: 10px 15px;
            text-decoration: none;
            font-family: $average_font;
            font-size: 0.8em;
            font-weight: 400;
            transition: all 300ms ease-in;

            text-transform: uppercase;
            border-radius: 5px;
            white-space: nowrap;
            width: fit-content;
            min-width: 260px;
            &:disabled {
                border: 1px solid $light_border;
                background: $light_main_color;
                color: $light_border;
                cursor: auto;
            }
        }
        p {
            font-size: 0.7em !important;
            margin: 5px 0 !important;
            color: $middle_font_color;
            &.error {
                color: red !important;
            }
            span {
                color: inherit;
            }
        }
    }
    .linksWrapper {
        display: flex;
        justify-content: space-between;
        padding-top: 30px;
        margin-bottom: 50px;

        a {
            text-decoration: none;
            font-size: 14px;
            .text {
                white-space: nowrap;
                max-width: 160px;
                text-overflow: ellipsis;
                overflow: hidden;
                color: $light_font_color;
                &:hover {
                    color: $additional_color !important;
                    svg {
                        path {
                            color: $additional_color !important;
                        }
                    }
                }
            }

            color: $light_font_color;
            svg {
                path {
                    color: $light_font_color;
                }
            }

            &:hover {
                color: $additional_color !important;
                svg {
                    path {
                        color: $additional_color !important;
                    }
                }
            }
        }
    }
    .tableWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        table {
            border-collapse: collapse;
            tr,
            td {
                border: 1px solid $middle_font_color;
            }
            td {
                font-size: 1em;
                text-align: center;
                padding: 5px 10px;
            }
        }
        &.tableWrapper_data {
            table {
                tr {
                    td {
                        &:last-child {
                            text-align: left;
                        }
                    }
                }
            }
        }
        p {
            margin-top: 10px;
            font-size: 0.9em;
            color: $light_font_color;
        }
        margin-bottom: 1em;
    }
    .tabs {
        max-width: 1180px;
        margin: 0 auto;
        position: relative;
        display: flex;
        justify-content: space-between;
        .tabs_menu {
            width: 300px;
            min-width: 300px;
            max-width: 300px;
            flex-shrink: 0;
            h3 {
                font-size: 16px;
                margin-bottom: 0;
                margin-top: 15px;
                color: $light_font_color;
            }
            ul,
            li {
                cursor: pointer;
                padding: 3px 0;
                color: $middle_font_color;
                outline: none;
                list-style-type: none;
                font-size: 14px;
                a {
                    text-decoration: none;

                    &:hover {
                        color: $contrast_color;
                    }
                }
                &.react-tabs__tab--selected {
                    color: $contrast_color;
                    a {
                        color: $contrast_color;
                    }
                }
                &:hover {
                    color: $light_font_color;
                }
                &.menuHeader {
                    cursor: default;
                    &:hover {
                        color: $middle_font_color;
                    }
                }
            }
        }
        .react-tabs__tab-panel--selected {
            min-height: 500px;
        }
        .react-tabs__tab-panel {
            h4 {
                font-size: 36px;
                color: $middle_font_color;
                margin-bottom: 40px;
                font-weight: normal;
                font-family: $header_font;
            }
            h6 {
                font-size: 16px;
                margin-bottom: 20px;
                font-family: $header_font;
            }
            p,
            pre {
                margin-bottom: 30px;
                font-size: 16px;
            }
            ul {
                padding-left: 20px;
            }
            .infographic {
                margin-top: 0;
                margin-bottom: 30px;
            }
        }
    }
    .dd-input {
        display: none;
    }
    .dd-button {
        font-size: 24px;
        font-family: $header_font;
        margin-bottom: 10px;
        span {
            visibility: hidden;
        }
    }
}
@media screen and (max-width: 1200px) {
    .termsWrapper_learn {
        padding: 50px;
        .tabs {
            .tabs_menu {
                width: 200px;
                min-width: 200px;
                max-width: 200px;
                flex-shrink: 0;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .decstopHidden {
        display: none;
    }
    .tabs_menu {
        ul,
        li {
            max-width: 250px;
        }
    }
}
@media screen and (max-width: 768px) {
    .hiddenonMobile {
        display: none;
    }
    .termsWrapper_learn {
        padding: 50px;
        .linksWrapper {
            display: flex;
            justify-content: space-between;
            padding-top: 20px;
            margin-bottom: 20px;
            span {
                background: $middle_main_color;
                border-radius: 3px;
                padding: 5px;
            }

            .text {
                display: none;
            }
        }
        .tabs {
            display: flex;
            flex-direction: column;
            .react-tabs__tab-panel {
                h4 {
                    font-size: 28px;
                    margin-bottom: 25px;
                }
                h6 {
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }
        }

        .tabs_menu {
            margin-bottom: 30px;
        }
        .tabs_body {
            padding-top: 25px;
        }
        .dropdown {
            display: inline-block;
            position: relative;
            width: 100% !important;
            max-width: 100% !important;
            cursor: pointer;
        }

        .dd-button {
            display: inline-block;
            border: 1px solid $light_border;
            border-radius: 4px;
            padding: 10px 30px 10px 20px;
            background-color: $light_main_color;
            cursor: pointer;
            white-space: nowrap;
            width: 100%;
            font-size: 18px;
            margin: 0 !important;
            span {
                visibility: visible;
                position: absolute;
                right: 15px;
                path {
                    color: $light_border !important;
                }
            }
        }

        .dd-menu {
            position: absolute;
            width: 100%;
            top: 100%;
            border: 1px solid #ccc;
            padding: 1rem !important;
            background-color: #ffffff;
            list-style-type: none;
            z-index: 9;
            margin: 0;
            margin-top: -2px;
            .section_header {
                font-size: 18px !important;
                margin-bottom: 0 !important;
            }
        }

        .dd-input + .dd-menu {
            display: none;
        }
        .dd-input:checked + .dd-menu {
            display: block !important;
        }

        .dd-menu li {
            padding: 10px 20px;
            cursor: pointer;
        }
        .MJXc-display {
            max-width: 500px;
            overflow: scroll;
        }
    }
}
@media screen and (max-width: 480px) {
    .termsWrapper_learn {
        .MJXc-display {
            max-width: 300px;
            overflow: scroll;
        }
        padding: 20px;
        .tabs {
            tabs_menu {
                padding: 0 !important;
                min-width: auto !important;
            }
        }
        section {
            padding: 0 !important;
        }
    }
}
