.termsWrapper {
    &.termsWrapper_rules {
        max-width: 100%;
      padding: 30px 0 0;
      color: $dark_font_color;
        background: $light_main_color;
        h1 {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            font-family: $header_font;
        }
        .section_header {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            font-family: $header_font;
        }
        .section_text {
            font-family: $average_font;
            white-space: pre-wrap; /* css-3 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -pre-wrap; /* Opera 4-6 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
            line-height: 30px;
            //text-align: center;
            max-width: 1180px;
            margin: 0 auto;
            .infographic {
                width: 80%;
            }

            &.bold {
                font-family: $header_font;
            }
        }
        section {
            padding: 50px;
            > ul {
                list-style-type: disc;
                list-style-position: inside;
            }
            > ol {
                list-style-type: decimal;
                list-style-position: inside;
            }
            > ul ul,
            > ol ul {
                list-style-type: circle;
                list-style-position: inside;
                margin-left: 15px;
            }
            > ol ol,
            > ul ol {
                list-style-type: lower-latin;
                list-style-position: inside;
                margin-left: 15px;
            }

            &.dark_theme {
                background: #223c55;
                .section_header,
                .section_text {
                    color: $light_main_color;
                }
                .section_text {
                    margin-bottom: 20px;
                }
            }
            &.middle_theme {
                background: $middle_main_color;
            }
            .flex-wrapper {
                max-width: 1180px;
                margin: 0 auto;

                .left,
                .right {
                    flex: 1 1 50%;
                    padding: 0 20px;

                    .rules-item {
                        margin-bottom: 20px;
                        h4 {
                            text-align: left;
                            font-family: $header_font;
                            margin-bottom: 10px;
                            span {
                                display: inline-flex;
                                margin-right: 10px;
                                background: $contrast_color;
                                border-radius: 50%;
                                width: 24px;
                                height: 24px;
                                text-align: center;
                                color: #fff;
                                align-items: center;
                                justify-content: center;
                                padding-top: 3px;
                            }
                        }
                        .section_text {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .flex-wrapper {
        display: flex;
    }
    .infographic {
        &.main {
            display: block;
        }
        &.mobile {
            display: none;
        }
    }
    .video_rules {
        position: relative;
        padding-bottom: 400px;
        padding-top: 25px;
        height: 0;
        max-width: 1180px;
        margin: 0 auto;
    }
}
@media screen and (max-width: 768px) {
    .section_header {
        font-size: 26px !important;
        margin-bottom: 20px !important;
    }

    .video_rules {
        position: relative;
        padding-bottom: 400px;
        padding-top: 25px;
        height: 0;
        width: 90%;
        margin: 0 auto;
    }
    .flex-wrapper {
        display: block;
    }
    .termsWrapper {
        &.termsWrapper_rules {
            padding: 0;
        }
    }
    .left {
        margin-top: 10px !important;
        padding: 0 !important;
    }
    .right {
        padding: 0 !important;
    }
    .infographic {
        &.main {
            display: none;
        }
        &.mobile {
            display: block;
        }
    }
}
@media (max-width: 480px) {
    section {
        padding: 20px !important;
    }
    .video_rules {
        display: none !important;
    }
}

.container_rules_each_competition {
    display: flex;
    justify-content: center;
    max-width: 1180px;
    margin: 0 auto;
    flex-wrap: wrap;
    align-content: space-between;

    .competition_item {
        padding: 12px;
        flex: 0 0 33%;

        .competition_item_header {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            margin-top: 10px;
            font-family: 'Gotham Pro Medium';
        }

        h4 {
            font-family: 'Gotham Pro Medium';
            margin-bottom: 10px;
            line-height: 25px;
        }

        .section_text {
            margin-bottom: 20px;
        }

        span {
            display: inline-flex;
            margin-right: 10px;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            text-align: center;
            color: #fff;
            align-items: center;
            justify-content: center;
            padding-top: 3px;
        }
        .stock {
            background: #d66d36;
        }
        .bitcoin {
            background: #666666;
        }
        .futures {
            background: #3f51b5;
        }

        @media screen and (max-width: 780px) {
            flex: 0 0 100%;
            padding-left: 0;
        }
    }
}
