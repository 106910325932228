.wrapper_contacts {
    padding: 50px;
}
.checkbox_wrapper {
    padding: 0;
    display: flex;
    justify-content: left;
    align-items: center;
    .checkbox-custom {
        min-width: 25px !important;
    }
}
.filter_item {
    .label {
        text-transform: none !important;
    }
}

.contacts_wrapper {
    padding-top: 60px;
    padding-bottom: 50px;
    position: relative;
    &.contacts_wrapper_main {
        padding: 0;
    }
    form {
        margin: 0;
    }

    .filter_item {
        display: block;

        label {
            width: 100%;
            font-family: $average_font;
            font-size: 1em;
        }
        select,
        input[type='text'] {
            outline: none;
            border: 1px solid $light_border;
            border-radius: 5px;
            color: $middle_font_color;
            font-family: $average_font;
            text-transform: uppercase;
        }
        select {
            min-width: 300px;
            padding: 0 20px;
        }
        .checkbox {
            display: none;
        }
        .checkbox-custom {
            position: relative;
            width: 25px;
            height: 25px;
            border: 1px solid $global_label_color;
            border-radius: 0;
        }
        .checkbox-custom,
        .label {
            display: inline-block;
            vertical-align: middle;
            margin-left: 5px;
            text-transform: capitalize;
            font-family: $average_font;
        }
        .checkbox:checked + .checkbox-custom::before {
            content: '';
            display: block;
            margin: 15% auto auto;
            width: 5px;
            height: 10px;
            border: solid $middle_font_color;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
        }
        .checkbox_div {
            padding-top: 20px;
        }

        .trigger_filter {
            //background-color: #181a1b;
        }
    }
}
.section_header_contacts {
    font-family: $header_font;
    color: $global_header_color;
    margin-top: 0;
    text-align: center;
    margin-bottom: 40px;
    text-transform: none;
    font-size: 36px;
    font-weight: normal;
}
.about_wrapper {
    .contactItem {
        margin: 5% 0;

        h3 {
            font-family: $average_font;
            color: $contrast_color;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        p {
            padding: 0;
            font-size: 1.1em;
            margin-top: 0;
        }
    }
    .cont_info {
        font-family: $bold_font;
        font-size: 1em;
        color: $dark_font_color;
        font-weight: bold;
        a {
            &:hover {
                color: $contrast_color;
            }
        }
    }
    .soc_darkWrapper {
        padding: 3px 1px;
        display: flex;
        justify-content: left;
        align-items: center;
        a {
            text-decoration: none;
            font-family: $average_font;
            font-weight: normal;
            cursor: pointer;
            color: $middle_font_color;
            &:hover {
                color: $contrast_color;
            }
        }
    }
    .soc_dark_vk,
    .soc_dark_fb,
    .soc_dark_in,
    .soc_dark_tg,
    .soc_dark_zen {
        height: 20px;
        width: 20px;
        background-image: url('../img/soc_icons.png');
        background-repeat: no-repeat;
        display: inline-block;
        margin-right: 15px;
    }
    .soc_dark_vk {
        background-position: -143px -9px;
    }
    .soc_dark_fb {
        background-position: -176px -9px;
    }
    .soc_dark_in {
        background-position: -176px -43px;
    }
    .soc_dark_tg {
        background-position: -143px -42px;
    }
    .soc_dark_zen {
        background-position: -109px -42px;
    }
    .mainInfoWrapper {
        .emailInfo {
            font-weight: 400;
            font-size: 1.1em;
            margin-top: 0;
        }
        .addrInfo {
            font-size: 1.1em;
            font-weight: 400;
            width: 100%;
            text-align: left;
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
        }
    }
}
.teamPhoto {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.about_image.about_image_map {
    width: 100%;
    max-width: 700px;
    .imageContainer {
        background-image: url('../img/map_office_spb.jpg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 0;
        padding-top: 66.64%; /* (img-height / img-width * container-width) */
        /* (853 / 1280 * 100) */
    }
    background: url('../img/map_office_spb.jpg');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center;
}
.contactForm {
    width: 100%;
    max-width: 1180px;

    h3 {
        font-family: $average_font;
        font-weight: 700;
        font-size: 1.2em;
        color: $dark_font_color;
        margin-top: 5%;
        text-align: center;
        margin-bottom: 4%;
        width: 100%;
    }
    .teamImage {
        width: 100%;
        max-width: 500px;
        height: 500px;
        background: url('../img/ice_petersburg.jpg');
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        margin-right: 5%;
    }
    .about_wrapper {
        width: 100%;
        p {
            margin: 0;
            font-size: 1em;
        }
    }
}
.about_wrapper {
    &.about_wrapper_contacts {
        padding-right: 20px;
        .addressInfo {
            text-align: left;
            width: 100%;
            padding-top: 0;
            margin-top: 0;
            font-size: 21px;
            font-family: $header_font;
        }
        h6 {
            text-transform: uppercase;
            font-size: 21px;
            line-height: 32px;
            margin-bottom: 10px;
        }
        p {
            color: $global_p_color;
            font-size: 21px;
            line-height: 32px;
            margin-bottom: 10px;
        }
        a {
            color: $global_link_color;
        }
    }
    .addressInfoTitle {
        color: $middle_font_color !important;
        font-size: 1.2em !important;
    }

    .addressLink {
        color: $contrast_color !important;
        text-decoration: none;
        font-family: $average_font;
    }
}
.socButtonWrapper {
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    a {
        border-radius: 50%;
        cursor: pointer;
        margin: 0 2% 2% 2%;
    }
}
.contactFormContainer {
    p.addrInfo {
        padding: 0;
        text-align: left;
        font-size: 1.1em;
        font-weight: 400;
        margin-bottom: 3%;
    }
    .contactsWrapper {
        display: flex;
        justify-content: space-between;

        section {
            width: 100%;
            input {
                width: 100%;
                padding: 2% 0;
            }
        }
    }
    .copied {
        position: relative;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        float: right;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
        &.success {
            &::after {
                position: absolute;
                padding: 5px;
                border-radius: 5px;
                width: 150px;
                content: attr(data-tooltip);
                top: -38px;
                color: #ffffff;
                left: -20px;
                border: 2px solid #28b300;
                background: #28b300;
                font-size: 1em;
                -webkit-transition: all 1s ease 0s;
                transition: all 1s ease 0s;
                text-align: center;
            }
            &:before {
                content: ' ';
                position: absolute;
                background: #28b300;
                width: 10px;
                height: 10px;
                top: -11px;
                left: 73px;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                transition: all 1s ease 0s;
            }
        }
    }
    .personaldataWrapper {
        display: flex;
        justify-content: flex-start;
        input[type='checkbox'] {
            width: 20px;
        }
        label {
            color: $middle_font_color;

            a {
                font-family: $average_font;
                font-size: 0.8em;
                color: $middle_font_color;
                text-decoration: none;
                &:hover {
                    color: $contrast_color;
                }
            }
        }
    }
    form label,
    form input,
    form textarea {
        color: $global_p_color;
    }

    form input,
    form textarea {
        width: 100%;
        display: block;
        font-family: $average_font;
        font-size: 1em;
        padding: 2% 1%;
        border: 1px solid $global_select_border_color;
        background-color: $global_textarea_color;
    }
    .buttonsWrapper {
        display: flex;
        justify-content: flex-start;
    }
    .button {
        margin-left: 2%;
        width: 150px;
        max-width: 150px !important;
        color: $light_main_color;
        text-transform: capitalize;
        padding: 15px;
        font-family: $bold_font;
        font-size: 0.9em;
        outline: none;
        background-color: rgb(163, 78, 33);
        border-color: rgb(145, 69, 30);
        cursor: pointer;
        transition: all 300ms ease-in;
        float: right;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
            rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
        &:hover {
            background-color: rgb(169, 74, 2);
            border-color: rgb(160, 70, 2);
            color: rgb(232, 230, 227);
        }
        &:disabled {
            cursor: default;
            background-color: rgb(23, 37, 42);
            border-color: rgb(62, 68, 70);
            color: rgb(168, 160, 149);
        }
    }

    .errorMsg {
        color: #cc0000;
        font-family: $average_font;
        font-size: 0.7em;
        margin-bottom: 12px;
    }

    .sucessMsg {
        color: #6b8e23;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 768px) {
    .copied {
        float: none !important;
        margin: auto !important;
    }
    .contacts_wrapper {
        margin-top: 0;
        .section_header_contacts {
            font-size: 26px;
            margin: 0;
            margin-bottom: 25px;
        }
    }
    .about_container.contactForm .about_wrapper .contactFormContainer .contactForm form input,
    .about_container.contactForm .about_wrapper .contactFormContainer .contactForm form textarea {
        font-size: 1em !important;
        padding: 10px !important;
        box-sizing: border-box;
    }

    .about_wrapper {
        padding: 0;
        padding-top: 15px;

        &.about_wrapper_contacts {
            padding: 0;
            padding-right: 20px;
            .addressInfo {
                color: $middle_font_color;
                text-align: left;
                width: 100%;
                padding: 0 !important;
                margin-top: 0;
                font-size: 16px;
                font-family: $header_font;
            }
            h6 {
                display: none;
            }
            p {
                display: none;
            }
        }
        .addressInfoTitle {
            color: $middle_font_color !important;
            font-size: 1.2em !important;
        }

        .addressLink {
            color: $contrast_color !important;
            text-decoration: none;
            font-family: $average_font;
        }
    }
}
@media only screen and (max-width: 768px) {
    .wrapper_contacts {
        padding: 20px !important;
    }
}
