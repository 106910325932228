.globalRankWrapper {
    .promoText {
        max-width: 400px;
        padding: 20px;
        color: $light_font_color;
        font-size: 12px;
        text-align: center;
        margin: 0 auto;
    }
    &.lastResults {
        .button-wrapper {
            margin-top: 0 !important;
        }
        padding: 50px;
        background: $middle_main_color;
        .global_list {
            padding: 50px 0;
            padding-bottom: 30px;
            max-width: 1000px;
            .subTableHeader {
                background: transparent;
            }
            .subTableHeader tr.shrink-wrapper {
                display: table-row;
            }
            .subTableCompetition {
                border: 1px solid $light_border;
                background: $light_main_color;
                &.crypto {
                    border-left: 5px solid #1c67ae;
                    .type {
                        color: #1c67ae;
                    }
                }
                &.stocks {
                    border-left: 5px solid $contrast_color;
                    .type {
                        color: $contrast_color;
                    }
                }
                td {
                    padding: 30px 10px;
                    width: 20%;
                    vertical-align: middle;
                    &.action {
                        border-right: 1px solid $light_border;
                        width: 100px;
                        max-width: 100px;
                        min-width: 100px;
                        cursor: pointer;
                        svg {
                            path {
                                color: $light_font_color;
                            }
                        }
                    }
                    &.type {
                        text-transform: uppercase;
                        font-size: 22px;
                        font-family: $header_font;
                    }
                    &.prize_th {
                        color: $dark_font_color;
                        font-size: 22px;
                        font-family: $header_font;
                        text-align: right;
                        vertical-align: center;
                        .currency {
                            font-size: 14px;
                            text-transform: uppercase;
                            color: $light_font_color;
                        }
                    }
                    &.duration,
                    &.start,
                    &.finish {
                        text-align: center;
                        padding-left: 20px;
                        padding-right: 0;
                        p {
                            text-align: left;
                            font-size: 18px;
                            color: $dark_font_color;
                            span {
                                color: $dark_font_color;
                            }
                            &:first-child {
                                font-size: 14px;
                                color: $light_font_color;
                            }
                        }
                    }
                }
            }
            .shrink-wrapper {
                background: $light_main_color;
                border: 1px solid $light_border;
                border-top: none;
                .mobile_Table {
                    display: none;
                    margin: 0 auto;
                    background: $middle_main_color;
                    td {
                        font-size: 12px;
                        vertical-align: middle;
                        background: $middle_main_color !important;
                    }
                }
                td {
                    background: $light_main_color !important;
                    .winnersTable {
                        display: flex;
                        justify-content: flex-end;
                        table {
                            width: 100%;
                            tr,
                            td {
                                border-bottom: 1px solid $middle_main_color;
                            }
                            tr {
                                border-top: 1px solid $middle_main_color;
                                &:first-child {
                                    border-top: none;
                                }
                                td {
                                    font-size: 14px;
                                    padding: 15px 10px !important;
                                    text-align: left;

                                    &.type {
                                        width: 18%;
                                    }
                                    &.name {
                                        width: 40%;
                                        max-width: 330px;
                                    }
                                    &.prize {
                                        white-space: nowrap;
                                        text-align: right;
                                        font-family: $header_font;
                                        .currency {
                                            font-family: $average_font;
                                            color: $light_font_color;
                                        }
                                    }
                                    &.action {
                                        width: 80px;
                                        max-width: 80px;
                                        min-width: 80px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.crypto {
                    border-left: 5px solid #1c67ae;
                }
                &.stocks {
                    border-left: 5px solid $contrast_color;
                }
                &.m-fadeOut {
                    border-bottom-width: 0;
                    transition: border-bottom-width 0.5s ease-out;
                }
            }
            .border {
                height: 10px;
                background: $middle_main_color;
                border: none !important;
                td {
                    padding: 10px;
                    border: none !important;
                    opacity: 0 !important;
                }
            }

            .tr_group_month_year {
                background-color: #0e90d2;
            }
        }
    }
}

@media screen and (max-width: 950px) {
    .globalRankWrapper {
        &.lastResults {
            .global_list {
                .subTableCompetition {
                    td {
                        width: 30%;

                        &.start,
                        &.finish {
                            display: none;
                        }
                        &.action {
                            width: 40px;
                            min-width: 40px;
                        }
                    }
                }
                .shrink-wrapper {
                    .mobile_Table {
                        display: block;
                    }
                    td {
                        .winnersTable {
                            display: flex;
                            justify-content: flex-end;
                            table {
                                width: 100%;
                                tr,
                                td {
                                    border-bottom: 1px solid $middle_main_color;
                                }
                                tr {
                                    border-top: 1px solid $middle_main_color;
                                    &:first-child {
                                        border-top: none;
                                    }
                                    td {
                                        font-size: 12px;
                                        padding: 15px 3px !important;
                                        text-align: left;
                                        white-space: nowrap;

                                        &.type {
                                            display: none;
                                        }
                                        &.name {
                                            width: 30%;
                                            max-width: 330px;
                                            white-space: pre-wrap;
                                        }
                                        &.prize {
                                            white-space: nowrap;
                                            text-align: right;
                                            font-family: $header_font;
                                            .currency {
                                                font-family: $average_font;
                                                color: $light_font_color;
                                            }
                                        }
                                        &.action {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &.crypto {
                        border-left: 5px solid #1c67ae;
                    }
                    &.stocks {
                        border-left: 5px solid $contrast_color;
                    }
                    &.m-fadeOut {
                        border-bottom-width: 0;
                        transition: border-bottom-width 0.5s ease-out;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .globalRankWrapper {
        &.lastResults {
            .global_list {
                .subTableCompetition {
                    td {
                        width: 50%;
                        font-size: 12px;
                        &.type {
                            font-size: 14px;
                        }

                        &.duration,
                        &.start,
                        &.finish {
                            text-align: center;
                            padding-left: 20px;
                            padding-right: 0;
                            p {
                                font-size: 14px;
                                &:first-child {
                                    font-size: 12px;
                                }
                            }
                        }
                        &.prize_th {
                            font-size: 14px;
                            .currency {
                                font-size: 12px;
                            }
                            &.start,
                            &.finish,
                            &.prize_th {
                                display: none;
                            }
                            &.action {
                                min-width: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 480px) {
    .globalRankWrapper {
        &.lastResults {
            padding: 20px;
            .global_list {
                padding: 15px 0 !important;
                .subTableCompetition {
                    td {
                        width: auto;
                        &.start,
                        &.finish,
                        &.prize_th {
                            display: none;
                        }
                        &.action {
                            width: auto !important;
                            min-width: auto !important;
                        }
                    }
                }
            }
        }
    }
}
