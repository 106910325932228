.cookieControl {
    position: fixed;
    bottom: 0;
    z-index: 10000;
    width: 100%;
    min-height: 10%;
    background: $dark_main_color;
    padding: 30px;
    opacity: 0.7;
    &.cookieControl_hide {
        display: none;
    }
    &.cookieControl_show {
        display: block;
    }
    section {
        max-width: 1180px;
        margin: 0 auto;
        p,
        a {
            color: $light_main_color;
        }
        .checkbox_wrapper {
            padding: 20px 0 !important;
        }

        label {
            width: 100%;
            padding: 12px 20px;
            margin: 8px 0;
            font-family: $average_font;
            font-size: 1em;
        }
        select,
        input[type='text'] {
            outline: none;
            border: 1px solid $light_border;
            border-radius: 5px;
            color: $middle_font_color;
            font-family: $average_font;
            text-transform: uppercase;
        }
        select {
            min-width: 300px;
            padding: 0 20px;
        }
        .checkbox {
            display: none;
        }
        /* Задаем внешний вид для нашего кастомного чекбокса. Все обязательные свойства прокомментированы, остальные же свойства меняйте по вашему усмотрению */
        .checkbox-custom {
            position: relative; /* Обязательно задаем, чтобы мы могли абсолютным образом позиционировать псевдоэлемент внютри нашего кастомного чекбокса */
            width: 25px; /* Обязательно задаем ширину */
            height: 25px; /* Обязательно задаем высоту */
            border: 1px solid $middle_font_color;
            border-radius: 0;
        }
        /* Кастомный чекбокс и лейбл центрируем по вертикали. Если вам это не требуется, то вы можете убрать свойство vertical-align: middle из данного правила, но свойство display: inline-block обязательно должно быть */
        .checkbox-custom,
        .label {
            display: inline-block;
            vertical-align: middle;
            color: $contrast_color;
            font-family: $average_font;
        }
        .label {
            margin-left: 5px;
        }
        /* Если реальный чекбокс у нас отмечен, то тогда добавляем данный признак и к нашему кастомному чекбоксу  */
        .checkbox:checked + .checkbox-custom::before {
            content: '';
            display: block;
            margin: auto;
            margin-top: 15%;

            /*Make it a small rectangle so the border will create an L-shape*/
            width: 5px;
            height: 10px;

            /*Add a white border on the bottom and left, creating that 'L' */
            border: solid $middle_font_color;
            border-width: 0 2px 2px 0;

            /*Rotate the L 45 degrees to turn it into a checkmark*/
            transform: rotate(45deg);
        }
        .checkbox_div {
            padding-top: 20px;
        }
    }
}

@media screen and (max-width: 480px) {
    .cookieControl {
        padding: 0;
        section {
            p,
            a {
                font-size: 12px;
            }
        }
    }
}
