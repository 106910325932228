.rules_quantiacs_main {
    &.termsWrapper_rules {
        max-width: 100%;
        padding: 0;
        padding-top: 50px;
        color: $global_p_color;
        a {
            color: #3391ff;
        }

        h1 {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            font-family: $header_font;
        }

        .section_header {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            font-family: $header_font;
        }

        .section_text {
            font-family: $average_font;
            white-space: pre-wrap; /* css-3 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -pre-wrap; /* Opera 4-6 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
            line-height: 30px;
            //text-align: center;
            max-width: 1180px;
            margin: 0 auto;
            text-align: justify;

            .infographic {
                width: 80%;
            }

            &.bold {
                font-family: $header_font;
            }
        }

        section {
            padding: 50px;
            background-image: url("../img/homepage/background_blue_dark_5_g.webp");

            p > strong {
                font-weight: bold;
            }

            .section_ul {
                list-style-type: disc;
                list-style-position: inside;
                padding-left: 40px;
            }

            p > ul {
                list-style-type: disc;
                list-style-position: inside;
                padding-left: 40px;
            }

            p > ol {
                list-style-type: decimal;
                list-style-position: inside;
                padding-left: 40px;
            }

            ol li::marker {
                font-weight: bold;
            }

            > ul ul,
            > ol ul {
                list-style-type: circle;
                list-style-position: inside;
                margin-left: 15px;
                margin-top: 5px;
            }

            > ol ol,
            > ul ol {
                list-style-type: lower-latin;
                list-style-position: inside;
                margin-left: 15px;
            }

            .deadline_time {
                color: #1bbafb;
            }

            .section_text_link_to_full_rules {
                text-align: center;
            }

            .quantiacs_start_link {
                text-align: center;
                color: #ffffff;
                background: rgb(3, 134, 187);
                border: none;
                cursor: pointer;
                padding: 15px 15px;
                text-decoration: none;
                font-size: 1em;
                font-weight: bold;
                transition: all 300ms ease-in;
                min-width: 200px;
                width: 200px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px;
                display: block;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
                    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
            }

            &.dark_theme {
                background: rgb(27, 48, 68);
                background-image: url("../img/homepage/background_blue_dark_3_g.webp");

                .section_header,
                .section_text {
                    color: $dark_p_color;
                }

                .section_text {
                    margin-bottom: 20px;
                }
            }

            &.middle_theme {
                background: $middle_main_color;
                background-image: url("../img/homepage/background_blue_dark_4_g.webp");
            }

            .flex-wrapper {
                max-width: 1180px;
                margin: 0 auto;

                .left,
                .right {
                    flex: 1 1 50%;
                    padding: 0 20px;

                    .rules-item {
                        margin-bottom: 20px;

                        h4 {
                            text-align: left;
                            font-family: $header_font;
                            margin-bottom: 10px;
                            color: $global_strong_color;

                            span {
                                display: inline-flex;
                                margin-right: 10px;
                                background: $contrast_color;
                                border-radius: 50%;
                                width: 24px;
                                height: 24px;
                                text-align: center;
                                color: #fff;
                                align-items: center;
                                justify-content: center;
                                padding-top: 3px;
                            }
                            .stock {
                                background: #d66d36;
                            }

                            .bitcoin {
                                background: #666666;
                            }

                            .futures {
                                background: #3f51b5;
                            }
                        }
                    }
                }
            }
        }
    }
}

.rules_quantiacs_main_home_page {
    &.termsWrapper_rules {
        max-width: 100%;
        padding: 0;
        padding-top: 50px;
        color: $dark_font_color;
        background: $light_main_color;

        h1 {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            font-family: $header_font;
        }

        .section_header {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            font-family: $header_font;
        }

        .section_text {
            font-family: $average_font;
            white-space: pre-wrap; /* css-3 */
            white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
            white-space: -pre-wrap; /* Opera 4-6 */
            white-space: -o-pre-wrap; /* Opera 7 */
            word-wrap: break-word; /* Internet Explorer 5.5+ */
            line-height: 30px;
            //text-align: center;
            max-width: 1180px;
            margin: 0 auto;
            text-align: justify;

            .infographic {
                width: 80%;
            }

            &.bold {
                font-family: $header_font;
            }
        }

        section {
            padding: 50px;

            p > a {
                padding-left: 5px;
            }

            p > a > strong {
                font-weight: bold;
            }

            p > strong {
                font-weight: bold;
            }

            .section_ul {
                list-style-type: disc;
                list-style-position: inside;
                padding-left: 40px;
            }

            p > ul {
                list-style-type: disc;
                list-style-position: inside;
                padding-left: 40px;
            }

            p > ol {
                list-style-type: decimal;
                list-style-position: inside;
                padding-left: 40px;
            }

            ol li::marker {
                font-weight: bold;
            }

            > ul ul,
            > ol ul {
                list-style-type: circle;
                list-style-position: inside;
                margin-left: 15px;
                margin-top: 5px;
            }

            > ol ol,
            > ul ol {
                list-style-type: lower-latin;
                list-style-position: inside;
                margin-left: 15px;
            }

            .deadline_time {
                color: #1bbafb;
            }

            .total_win_quantiacs {
                color: red;
                font-weight: bold;
            }

            .quantiacs_start_link {
                text-align: center;
                color: #ffffff;
                background: #1bbafb;
                border: none;
                cursor: pointer;
                padding: 15px 15px;
                text-decoration: none;
                font-size: 1em;
                font-weight: bold;
                transition: all 300ms ease-in;
                min-width: 200px;
                width: 200px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px;
                display: block;
                box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
                    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
            }

            &.dark_theme {
                background: #223c55;

                .section_header,
                .section_text {
                    color: $light_main_color;
                }

                .section_text {
                    margin-bottom: 20px;
                }
            }

            &.middle_theme {
                background: $middle_main_color;
            }

            .flex-wrapper {
                max-width: 1180px;
                margin: 0 auto;

                .left,
                .right {
                    flex: 1 1 50%;
                    padding: 0 20px;

                    .rules-item {
                        margin-bottom: 20px;

                        h4 {
                            text-align: left;
                            font-family: $header_font;
                            margin-bottom: 10px;

                            span {
                                display: inline-flex;
                                margin-right: 10px;
                                background: $contrast_color;
                                border-radius: 50%;
                                width: 24px;
                                height: 24px;
                                text-align: center;
                                color: #fff;
                                align-items: center;
                                justify-content: center;
                                padding-top: 3px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .flex-wrapper {
        display: flex;
    }
    .infographic {
        &.main {
            display: block;
        }

        &.mobile {
            display: none;
        }
    }
    .video_rules {
        position: relative;
        padding-bottom: 400px;
        padding-top: 25px;
        height: 0;
        max-width: 1180px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .section_header {
        font-size: 26px !important;
        margin-bottom: 20px !important;
    }

    .video_rules {
        position: relative;
        padding-bottom: 400px;
        padding-top: 25px;
        height: 0;
        width: 90%;
        margin: 0 auto;
    }
    .flex-wrapper {
        display: block;
    }
    .termsWrapper {
        &.termsWrapper_rules {
            padding: 0;
        }
    }
    .left {
        margin-top: 10px !important;
        padding: 0 !important;
    }
    .right {
        padding: 0 !important;
    }
    .infographic {
        &.main {
            display: none;
        }

        &.mobile {
            display: block;
        }
    }
}

@media (max-width: 480px) {
    section {
        padding: 20px !important;
    }
    .video_rules {
        display: none !important;
    }
}

.container_rules_each_competition {
    display: flex;
    justify-content: center;
    max-width: 1180px;
    margin: 0 auto;
    flex-wrap: wrap;
    align-content: space-between;

    .competition_item {
        padding: 12px;
        flex: 0 0 33%;

        .competition_item_header {
            text-align: center;
            font-size: 36px;
            margin-bottom: 40px;
            margin-top: 10px;
            font-family: 'Gotham Pro Medium';
        }

        h4 {
            font-family: 'Gotham Pro Medium';
            margin-bottom: 10px;
            line-height: 25px;
        }

        .section_text {
            margin-bottom: 20px;
        }

        span {
            display: inline-flex;
            margin-right: 10px;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            text-align: center;
            color: #fff;
            align-items: center;
            justify-content: center;
            padding-top: 3px;
        }

        .stock {
            background: #d66d36;
        }

        .bitcoin {
            background: #666666;
        }

        .futures {
            background: #3f51b5;
        }

        @media screen and (max-width: 780px) {
            flex: 0 0 100%;
            padding-left: 0;
        }
    }
}
